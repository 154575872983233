<template>
  <a-row :gutter="16">
    <a-col :md="24" :lg="8">
      <a-form layout="vertical" :form="form">
        <a-form-item
          label="原始密码"
          :required="true">
          <a-input type="Password" placeholder="请输入原始密码" v-decorator="[
          'oldPassword',
          {rules: [{ required: true, message: '请输入原始密码' }]}
        ]"/>
        </a-form-item>
        <a-form-item
          label="新密码"
          :required="true">
          <a-input type="Password" placeholder="请输入新密码" v-decorator="[
          'newPassword',
          {rules: [{ required: true, message: '请输入新密码' },
          { validator: validatorPassword, message: '必须包含数字、大小写字母、特殊符号且大于等于8位'}]}
        ]"/>
        </a-form-item>
        <a-form-item
          label="确认密码"
          :required="true">
          <a-input type="Password" placeholder="请输入确认密码" v-decorator="[
          'confirmPassword',
          {rules: [{ required: true, message: '请输入确认密码' }]}
        ]"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleSubmit">修改密码</a-button>
        </a-form-item>
      </a-form>
    </a-col>
  </a-row>
</template>
<script>
  import SERVICE_URLS from '../../api/service.url'

  export default {
    data() {
      return {
        form: this.$form.createForm(this)
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    methods: {
      handleSubmit() {
        this.form.validateFields(
          (err, value) => {
            if (!err) {
              if (value.newPassword === value.confirmPassword) {
                this.$http(this, {
                  url: SERVICE_URLS.auth.editPassword,
                  data: {
                    newPassword: value.newPassword,
                    oldPassword: value.oldPassword
                  },
                  loading: 'ifSub',
                  successTipsContent: '密码修改成功！',
                  noTips: 'true',
                  success: (data) => {
                    this.$message.info('密码修改成功！')
                  }
                })
              } else {
                this.$message.error('请检查您设置的密码时候一致！')
              }
            }
          }
        )
      },
      validatorPassword(rule, value, callback) {
        let passwordReg =  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[~!@#$%^&*]).*$/
        if (!passwordReg.test(value) || value.length < 8) {
          callback('必须包含数字、大小写字母、特殊符号且大于等于8位')
          return
        }
        callback()
      }
    }
  }
</script>

<style scoped>

</style>
